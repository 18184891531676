const oauth = require("oauth-signature");

const baseLtiPayload = {
  lti_message_type: "basic-lti-launch-request",
  lti_version: "LTI-1p0",
  oauth_version: "1.0",
  oauth_signature_method: "HMAC-SHA1",
  launch_presentation_locale: "en-US",
  launch_presentation_return_url: "https://lti-app.act-et.org",
  oauth_nonce: "",
  oauth_timestamp: 0,
  lis_result_sourcedid: "vijay_1210",
  resource_link_id: 1,
};


export const launchRes = (res: string, customer: any) => {
  let { ltiSecret, url, env, ltiKey, newTab, ...ltiPayload } = customer;

  let assessLtiPayload = {
    ...baseLtiPayload,
    ...ltiPayload,
    oauth_consumer_key: ltiKey,
  };
  let timestamp = Math.round(Date.now() / 1000);
  assessLtiPayload.oauth_nonce = btoa("" + timestamp);
  assessLtiPayload.oauth_timestamp = timestamp;
  if (assessLtiPayload.user_id === "0") {
    assessLtiPayload.user_id = timestamp;
  }
  assessLtiPayload.path = res;
  // if (ltiPayload.custom_selrole === "Instructor") {
  //   delete ltiPayload["custom_selrole"];
  // }
  
  assessLtiPayload.launchUrl = url + "/launch/content?path="+res;
  generateSignature(assessLtiPayload, ltiSecret, newTab);
};

export const launchApp = (appCode: string, customer: any) => {
  let { ltiSecret, url, env, ltiKey, newTab, ...ltiPayload } = customer;
  console.log("key=====" + newTab);

  let assessLtiPayload = {
    ...baseLtiPayload,
    ...ltiPayload,
    oauth_consumer_key: ltiKey,
  };
  let timestamp = Math.round(Date.now() / 1000);
  assessLtiPayload.oauth_nonce = btoa("" + timestamp);
  assessLtiPayload.oauth_timestamp = timestamp;
  if (assessLtiPayload.user_id === "0") {
    assessLtiPayload.user_id = timestamp;
  }
  if (ltiPayload.custom_selrole === "Instructor") {
    delete ltiPayload["custom_selrole"];
  }
  if (appCode === "sel") {
    assessLtiPayload.launchUrl = url + "/launch/report";
  } else {
    assessLtiPayload.launchUrl = url + "/launch/app/" + appCode;
  }
  if(assessLtiPayload.launchUrl.includes('?')){
    const url = new URL(assessLtiPayload.launchUrl);
    const params = new URLSearchParams(url.search);
    // @ts-ignore
    for (const [key, value] of params) {
      assessLtiPayload[key] = value;
    }
  }

  generateSignature(assessLtiPayload, ltiSecret, newTab);
};

export const launchAssessment = (
  formCode: string,
  customer: any
) => {

  let { ltiSecret, url, env, ltiKey, newTab, ...ltiPayload } = customer;

  let assessLtiPayload = {
    ...baseLtiPayload,
    ...ltiPayload,
    oauth_consumer_key: ltiKey,
  };
  let timestamp = Math.round(Date.now() / 1000);
  assessLtiPayload.oauth_nonce = btoa("" + timestamp);
  assessLtiPayload.oauth_timestamp = timestamp;
  if (assessLtiPayload.user_id === "0") {
    assessLtiPayload.user_id = timestamp;
  }
  if (ltiPayload.custom_selrole === "Instructor") {
    delete ltiPayload["custom_selrole"];
  }
  
  if (customer.env === 'ednexus') {
    assessLtiPayload.launchUrl = url + "/launch/" + formCode;
    assessLtiPayload.custom_domain = 'act-et.org';
    assessLtiPayload.resource_link_id = "c18e52d5-9606-4e77-b678-2d9467f8e539";
    assessLtiPayload.lis_outcome_service_url =
      "https://auth.sandbox.knov8.services/ednexus/listener";
    assessLtiPayload.launch_presentation_return_url =
      "https://auth.sandbox.knov8.services/ednexus/listener";
  } else {
    
    assessLtiPayload.launchUrl = url + "/launch/assessment/" + formCode;
  }

  
  generateSignature(assessLtiPayload, ltiSecret, newTab);
};

const generateSignature = (
  { launchUrl, ...launchPayload }: any,
  ltiSecret: string,
  newTab: boolean
) => {
  const signature = oauth.generate(
    "POST",
    launchUrl,
    launchPayload,
    ltiSecret,
    false,
    { encodeSignature: false }
  );
  const finalPayload = { ...launchPayload, oauth_signature: signature };
  post_to_url(launchUrl, finalPayload, newTab);
};

const post_to_url = (path: string, params: any, newTab: boolean) => {
  const method = "post";

  var form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", path);
  if (newTab) {
     form.setAttribute("target", "_blank");
  } else {
    var iframe = document.getElementsByTagName("iframe")[0];
    if (!iframe) {
      iframe = document.createElement("iframe");
    }
    iframe.style.height = "600px";
    iframe.style.width = "100%";
    iframe.setAttribute("name", "targetFrame");
    // iframe.setAttribute(
    //   "sandbox",
    //   "allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-forms"
    // );
    document.body.appendChild(iframe);
    form.setAttribute("target", "targetFrame");
  }
    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);

        form.appendChild(hiddenField);
      }
    }

  document.body.appendChild(form);
  form.submit();
};
