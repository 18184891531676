import React, { useState } from "react";
import "./App.css";
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  makeStyles,
  Theme,
  createStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import LtiAssessment from "../components/LtiAssessment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LtiCustomer from "../components/LtiCustomer";
import LtiApp from "../components/LtiApp";
import LtiResource from "../components/LtiResource";
import LtiEdnexus from "../components/LtiEdnexus";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

function App() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | false>("appPanel");
  const [customer, setCustomer] = useState({
    ltiKey: "testKey",
    ltiSecret: "testSecret",
    env: 'dev',
    url: 'https://lti-tool-dev.enc-np.com',
    roles: "Learner",
    custom_selrole: "Learner",
    context_id: "context_01",
    lis_person_name_family: "Sample",
    lis_person_name_given: "Sample",
    lis_person_name_middle: "",
    user_id: "123456",
    context_title: "class_name",
    custom_student_id: "12345678",
    custom_school_id: "12345678",
    custom_classcode: "Class5",
    custom_school_name: "IowaCity School",
    custom_district_id: "12345",
    custom_district_name: "Iowa City School District",
    newTab: true
  });

  const changeActivePanel = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            LTI Launch Portal
          </Typography>
        </Toolbar>
      </AppBar>
      <Box p={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <LtiCustomer
              handleCustomer={setCustomer}
              customer={customer}
            ></LtiCustomer>
          </Grid>
          <Grid item xs={12} sm={4}>
            {/*<Accordion*/}
            {/*  expanded={expanded === "ednexusPanel"}*/}
            {/*  onChange={changeActivePanel("ednexusPanel")}*/}
            {/*>*/}
            {/*  <AccordionSummary*/}
            {/*    expandIcon={<ExpandMoreIcon />}*/}
            {/*    aria-controls="panel1a-content"*/}
            {/*    id="ednexusPanel"*/}
            {/*  >*/}
            {/*    <Typography variant="h6">Ednexus Launch </Typography>*/}
            {/*  </AccordionSummary>*/}
            {/*  <AccordionDetails>*/}
            {/*    <LtiEdnexus customer={customer}></LtiEdnexus>*/}
            {/*  </AccordionDetails>*/}
            {/*</Accordion>*/}

            {/*<Accordion*/}
            {/*  expanded={expanded === "assessmentPanel"}*/}
            {/*  onChange={changeActivePanel("assessmentPanel")}*/}
            {/*>*/}
            {/*  <AccordionSummary*/}
            {/*    expandIcon={<ExpandMoreIcon />}*/}
            {/*    aria-controls="panel1a-content"*/}
            {/*    id="assessmentPanel"*/}
            {/*  >*/}
            {/*    <Typography variant="h6">Assessment Launch </Typography>*/}
            {/*  </AccordionSummary>*/}
            {/*  <AccordionDetails>*/}
            {/*    <LtiAssessment customer={customer}></LtiAssessment>*/}
            {/*  </AccordionDetails>*/}
            {/*</Accordion>*/}

            <Accordion
              expanded={expanded === "appPanel"}
              onChange={changeActivePanel("appPanel")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id="appPanel">
                <Typography variant="h6">App Launch</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <LtiApp customer={customer}></LtiApp>
              </AccordionDetails>
            </Accordion>

            {/*<Accordion*/}
            {/*  expanded={expanded === "resPanel"}*/}
            {/*  onChange={changeActivePanel("resPanel")}*/}
            {/*>*/}
            {/*  <AccordionSummary*/}
            {/*    expandIcon={<ExpandMoreIcon />}*/}
            {/*    aria-controls="panel1a-content"*/}
            {/*    id="resPanel"*/}
            {/*  >*/}
            {/*    <Typography variant="h6">Resource Launch</Typography>*/}
            {/*  </AccordionSummary>*/}
            {/*  <AccordionDetails>*/}
            {/*    <LtiResource customer={customer}></LtiResource>*/}
            {/*  </AccordionDetails>*/}
            {/*</Accordion>*/}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default App;
