import React, { useState } from "react";
import {
  Theme,
  Container,
  CssBaseline,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { launchApp } from "../util/lti";
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    width: "100%",
  },
}));

const LtiApp = (props: any) => {
  const customer = props.customer;
  const classes = useStyles();
  const [reportLtiPayload, setRepLtiPayload] = useState({
    app: "e4s-lti-form",
    launchUrl: "e4s-lti-form",
  });
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* {JSON.stringify(assessmentLtiPayload)} */}
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="r_app">App</InputLabel>
                <Select
                  labelId="role"
                  label="Role"
                  id="r_app"
                  value={reportLtiPayload.app}
                  onChange={(e) => {
                    if (typeof e.target.value === "string")
                      setRepLtiPayload({
                        ...reportLtiPayload,
                        app: e.target.value,
                      });
                  }}
                >
                  <MenuItem value={"e4s-lti-form"}>LtiForm</MenuItem>
                  <MenuItem value={"e4s-lti-web-form"}>LtiWebForm</MenuItem>
                  {/*<MenuItem value={"sel"}>SEL-Report</MenuItem>*/}
                  {/*<MenuItem value={"sel-dashboard"}>SEL-Dashboard</MenuItem>*/}
                  {/*<MenuItem value={"sel-test?code=sel-hs-pre"}>*/}
                  {/*  SEL-HS-PRE*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem value={"sel-test?code=sel-hs-post"}>*/}
                  {/*  SEL-HS-POST*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem value={"sel-test?code=sel-ms-pre"}>*/}
                  {/*  SEL-MS-PRE*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem value={"sel-test?code=sel-ms-post"}>*/}
                  {/*  SEL-MS-POST*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem value={"local"}>local-app</MenuItem>*/}
                  {/*<MenuItem value={"sel-test?code=sel-es-pre"}>SEL-ES-PRE</MenuItem>*/}
                  {/*<MenuItem value={"sel-test?code=sel-es-post"}>SEL-ES-POST</MenuItem>*/}
                  {/*<MenuItem value={"sel-test?code=sel-screener-hs-pre"}>SEL-SCREENER-HS-PRE</MenuItem>*/}
                  {/*<MenuItem value={"sel-test?code=sel-screener-hs-post"}>SEL-SCREENER-HS-POST</MenuItem>*/}
                  {/*<MenuItem value={"sel-test?code=sel-screener-ms-pre"}>SEL-SCREENER-MS-PRE</MenuItem>*/}
                  {/*<MenuItem value={"sel-test?code=sel-screener-ms-post"}>SEL-SCREENER-MS-POST</MenuItem>*/}
                  {/*<MenuItem value={"sel-test?code=sel-screener-es-pre"}>SEL-SCREENER-ES-PRE</MenuItem>*/}
                  {/*<MenuItem value={"sel-test?code=sel-screener-es-post"}>SEL-SCREENER-ES-POST</MenuItem>*/}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="button"
            id="r_launch"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              launchApp(reportLtiPayload.app, customer);
            }}
          >
            Launch App
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default LtiApp;
