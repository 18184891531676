import { createMuiTheme } from '@material-ui/core/styles';

export const COLORS = {
  BLACK: 'rgba(0, 0, 0, 1)',
  BLACK_12: 'rgba(0, 0, 0, 0.12)',
  BLACK_38: 'rgba(0, 0, 0, 0.38)',
  BLACK_54: 'rgba(0, 0, 0, 0.54)',
  BLACK_87: 'rgba(0, 0, 0, 0.87)',
  LINK: '#1976d2',
  PRIMARY: '#0079ad',
  PRIMARY_DARK: '#0061a2',
  REQUIRED: '#ff0000',
  TEXT_PRIMARY: '#2d2d2d',
  TEXT_SECONDARY: '#8f8f8f',
  TRANSPARENT: 'rgba(255, 255, 255, 0)',
  WHITE: 'rgba(255, 255, 255, 1)',
  WHITE_12: 'rgba(255, 255, 255, 0.12)',
  WHITE_38: 'rgba(255, 255, 255, 0.38)',
  WHITE_54: 'rgba(255, 255, 255, 0.54)',
  WHITE_87: 'rgba(255, 255, 255, 0.87)',
};

export const TYPOGRAPHY = {
  WEIGHT_BOLD: 700,
  WEIGHT_LIGHT: 300,
  WEIGHT_MEDIUM: 600,
  WEIGHT_REGULAR: 500,
};

export const theme = {
  palette: {
    primary: {
      dark: COLORS.PRIMARY,
      light: COLORS.PRIMARY,
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.WHITE,
    },
    text: {
      primary: COLORS.TEXT_PRIMARY,
      secondary: COLORS.TEXT_SECONDARY,
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Helvetica', 'sans-serif'].join(','),
    fontWeightBold: TYPOGRAPHY.WEIGHT_BOLD,
    fontWeightLight: TYPOGRAPHY.WEIGHT_LIGHT,
    fontWeightMedium: TYPOGRAPHY.WEIGHT_MEDIUM,
    fontWeightRegular: TYPOGRAPHY.WEIGHT_REGULAR,
    htmlFontSize: 18,
  },
};

export default createMuiTheme(theme);
