import React from "react";
import {
  Theme,
  Container,
  CssBaseline,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
// import "./LtiAssessment.css";
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    width: "100%",
  },
}));

const LtiCustomer = (props: any) => {
  const classes = useStyles();
  const customer = props.customer;
  const setCustomer = props.handleCustomer;

  const changeEnv = (env: string) => {
    let url = "";

    switch (env) {
      case "dev":
        url = "https://lti-tool-dev.enc-np.com";
        break;
      case "stage":
        url = "https://lti-tool-stg.enc-np.com";
        break;
      case "prod":
        url = "https://lti-tool.enc-et.com";
        break;
      case "local":
        url = "http://localhost:4008";
        break;
    }
    if (env === "ednexus") {
      setCustomer({
        ...customer,
        url: url,
        ltiKey: "7d87541cbffda7db31479f6d7a5c39e8",
        ltiSecret: "892332fb598b1aa048df96bb961b2f93",
        env: env,
      });
    } else {
      setCustomer({
        ...customer,
        ltiKey: "testKey",
        ltiSecret: "testSecret",
        url: url,
        env: env,
      });
    }
  };
  // const [customer, setCustomer] = useState({
  //   ltiKey: "SuperKey",
  //   ltiSecret: " SuperSecret",
  // });
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* {JSON.stringify(customer)} */}
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="environment">Environment</InputLabel>
                <Select
                  labelId="environment"
                  label="Envrionment"
                  id="environment"
                  value={customer.env}
                  onChange={(e) => {
                    if (typeof e.target.value === "string")
                      changeEnv(e.target.value);
                  }}
                >
                  <MenuItem value={"dev"}>Dev</MenuItem>
                  <MenuItem value={"stage"}>Stage</MenuItem>
                  <MenuItem value={"prod"}>Prod</MenuItem>
                  <MenuItem value={"local"}>Local</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="url"
                label="URL"
                name="url"
                value={customer.url}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    url: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="key"
                variant="outlined"
                required
                fullWidth
                id="key"
                label="Key"
                value={customer.ltiKey}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    ltiKey: e.target.value,
                  });
                }}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                type="password"
                id="secret"
                label="Secret"
                name="lastName"
                value={customer.ltiSecret}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    ltiSecret: e.target.value,
                  });
                }}
              />
            </Grid>

            {/* <Grid container spacing={2}> */}
            {/*<Grid item xs={12} sm={6}>*/}
            {/*  <TextField*/}
            {/*    autoComplete="fname"*/}
            {/*    name="firstName"*/}
            {/*    variant="outlined"*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    id="a_firstName"*/}
            {/*    label="First Name"*/}
            {/*    value={customer.lis_person_name_given}*/}
            {/*    onChange={(e) => {*/}
            {/*      setCustomer({*/}
            {/*        ...customer,*/}
            {/*        lis_person_name_given: e.target.value,*/}
            {/*      });*/}
            {/*    }}*/}
            {/*    autoFocus*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} sm={6}>*/}
            {/*  <TextField*/}
            {/*    variant="outlined"*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    id="a_lastName"*/}
            {/*    label="Last Name"*/}
            {/*    name="lastName"*/}
            {/*    value={customer.lis_person_name_family}*/}
            {/*    onChange={(e) => {*/}
            {/*      setCustomer({*/}
            {/*        ...customer,*/}
            {/*        lis_person_name_family: e.target.value,*/}
            {/*      });*/}
            {/*    }}*/}
            {/*    autoComplete="lname"*/}
            {/*  />*/}
            {/*</Grid>*/}

            {/*<Grid item xs={12} sm={6}>*/}
            {/*  <FormControl variant="outlined" className={classes.formControl}>*/}
            {/*    <InputLabel id="r_role">Role</InputLabel>*/}
            {/*    <Select*/}
            {/*      labelId="role"*/}
            {/*      label="Role"*/}
            {/*      id="r_role"*/}
            {/*      value={customer.roles}*/}
            {/*      onChange={(e) => {*/}
            {/*        if (typeof e.target.value === "string") {*/}
            {/*          let role = e.target.value;*/}
            {/*          if (*/}
            {/*            e.target.value === "school" ||*/}
            {/*            e.target.value === "district"*/}
            {/*          ) {*/}
            {/*            role = "Instructor";*/}
            {/*          }*/}
            {/*          setCustomer({*/}
            {/*            ...customer,*/}
            {/*            roles: role,*/}
            {/*            custom_selrole: e.target.value,*/}
            {/*          });*/}
            {/*        }*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <MenuItem value={"Learner"}>Learner</MenuItem>*/}
            {/*      <MenuItem value={"Instructor"}>Teacher</MenuItem>*/}
            {/*      <MenuItem*/}
            {/*        value={*/}
            {/*          "Instructor,Learner,urn:lti:instrole:ims/lis/Administrator"*/}
            {/*        }*/}
            {/*      >*/}
            {/*        Teacher with multi Roles*/}
            {/*      </MenuItem>*/}
            {/*      <MenuItem value={"school"}>School Admin</MenuItem>*/}
            {/*      <MenuItem value={"district"}>District Admin</MenuItem>*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}

            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="studentId"
                variant="outlined"
                required
                fullWidth
                id="a_studentId"
                label="Profile Id"
                value={customer.user_id}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    user_id: e.target.value,
                  });
                }}
                autoFocus
              />
            </Grid>

            {/*<Grid item xs={12} sm={6}>*/}
            {/*  <TextField*/}
            {/*    variant="outlined"*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    id="a_classId"*/}
            {/*    label="Class Id"*/}
            {/*    name="classId"*/}
            {/*    autoComplete="lname"*/}
            {/*    value={customer.context_id}*/}
            {/*    onChange={(e) => {*/}
            {/*      setCustomer({*/}
            {/*        ...customer,*/}
            {/*        context_id: e.target.value,*/}
            {/*      });*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} sm={6}>*/}
            {/*  <TextField*/}
            {/*    variant="outlined"*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    id="a_className"*/}
            {/*    label="Class Name"*/}
            {/*    name="className"*/}
            {/*    autoComplete="lname"*/}
            {/*    value={customer.context_title}*/}
            {/*    onChange={(e) => {*/}
            {/*      setCustomer({*/}
            {/*        ...customer,*/}
            {/*        context_title: e.target.value,*/}
            {/*      });*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} sm={6}>*/}
            {/*  <TextField*/}
            {/*    variant="outlined"*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    id="a_schoolId"*/}
            {/*    label="School Id"*/}
            {/*    name="schoolId"*/}
            {/*    autoComplete="lname"*/}
            {/*    value={customer.custom_school_id}*/}
            {/*    onChange={(e) => {*/}
            {/*      setCustomer({*/}
            {/*        ...customer,*/}
            {/*        custom_school_id: e.target.value,*/}
            {/*      });*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} sm={6}>*/}
            {/*  <TextField*/}
            {/*    variant="outlined"*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    id="a_schoolName"*/}
            {/*    label="School Name"*/}
            {/*    name="schoolName"*/}
            {/*    autoComplete="lname"*/}
            {/*    value={customer.custom_school_name}*/}
            {/*    onChange={(e) => {*/}
            {/*      setCustomer({*/}
            {/*        ...customer,*/}
            {/*        custom_school_name: e.target.value,*/}
            {/*      });*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}

            {/*<Grid item xs={12} sm={6}>*/}
            {/*  <TextField*/}
            {/*    variant="outlined"*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    id="a_districtId"*/}
            {/*    label="District Id"*/}
            {/*    name="districtId"*/}
            {/*    autoComplete="lname"*/}
            {/*    value={customer.custom_district_id}*/}
            {/*    onChange={(e) => {*/}
            {/*      setCustomer({*/}
            {/*        ...customer,*/}
            {/*        custom_district_id: e.target.value,*/}
            {/*      });*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} sm={6}>*/}
            {/*  <TextField*/}
            {/*    variant="outlined"*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    id="a_districtName"*/}
            {/*    label="District Name"*/}
            {/*    name="districtName"*/}
            {/*    autoComplete="lname"*/}
            {/*    value={customer.custom_district_name}*/}
            {/*    onChange={(e) => {*/}
            {/*      setCustomer({*/}
            {/*        ...customer,*/}
            {/*        custom_district_name: e.target.value,*/}
            {/*      });*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={customer.newTab}
                    color="primary"
                    onChange={(e) => {
                      setCustomer({
                        ...customer,
                        newTab: e.target.checked,
                      });
                    }}
                    inputProps={{ "aria-label": "New Tab" }}
                  />
                }
                label="New Tab"
              />
              {/* <TextField
                variant="outlined"
                required
                fullWidth
                id="a_districtName"
                label="District Name"
                name="districtName"
                autoComplete="lname"
                value={customer.custom_district_name}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    custom_district_name: e.target.value,
                  });
                }}
              /> */}
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default LtiCustomer;
